import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class ContactPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Contact Us" />
        <h1>Contact Us</h1>
        <p>
          For any concerns, questions, or inquiries please don't hesitate to
          reach out to us on
          <a href="https://twitter.com/addsubtitles"> Twitter</a> or
          <a href="mailto:addsubtitles@protonmail.com"> email.</a>
        </p>
        <p>We'd love to hear from you.</p>
      </Layout>
    )
  }
}

export default ContactPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
