import React from "react"
import { graphql } from "gatsby"
import rehypeReact from "rehype-react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MyAdSense from "../components/myAdsense"
import { GDPRConsent } from "../components/gdpr-consent"
import AmazonAd from "../components/amazon-ad"
import AdSidenav from "../components/ad-sidenav"
import { rhythm, scale } from "../utils/typography"
class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteSchema = this.props.data.markdownRemark.frontmatter.schema

    // const { previous, next } = this.props.pageContext
    const renderAst = new rehypeReact({
      createElement: React.createElement,
      components: {
        adsense: MyAdSense,
        amazon: AmazonAd,
        linkout: props => (
          <a className={props.className} href={props.href}>
            {props.children}
          </a>
        ),
        nordbanner: () => (
          <div>
            <a className="img-link" href="https://go.nordvpn.net/SH2iJ">
              <img alt="NordVPN Deal" src="/nordvpn-promo.png" />
            </a>
            <img
              src="https://go.nordvpn.net/aff_i?offer_id=15&aff_id=30380&tiny_url=1"
              alt=""
              width="0"
              height="0"
              style={{ position: "absolute", visibility: "hidden" }}
              border="0"
            />
          </div>
        ),
        protonbanner: () => (
          <div>
            <a className="img-link" href="https://proton.go2cloud.org/SHAZ">
              <img alt="ProtonVPN Deal" src="/protonvpn-banner.png" />
            </a>
            <img
              src="https://proton.go2cloud.org/aff_i?offer_id=10&aff_id=1242&tiny_url=1"
              width="0"
              height="0"
              class="banner-pixel"
              border="0"
              alt=""
            />
          </div>
        ),
      },
    }).Compiler

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        lang={post.frontmatter.lang}
      >
        <SEO
          title={post.frontmatter.title}
          lang={post.frontmatter.lang}
          description={post.frontmatter.description || post.excerpt}
          image={
            post.frontmatter.image ? post.frontmatter.image.publicURL : null
          }
          imageType={post.frontmatter.imageType}
          schema={siteSchema && {
            "@context": "http://schema.org",
            "@type": siteSchema._type,
            "name": siteSchema.name,
            "image": {
              "@type": "ImageObject",
              "url": siteSchema.image.url.publicURL,
              "width": siteSchema.image.width,
              "height": siteSchema.image.height
            },
            "step": siteSchema.step.map(step => ({
              "@type": "HowToStep",
              "text": step.text,
              "name"  : step.name
            }))
          }}
        />
        <GDPRConsent />

        <AdSidenav />

        <h1
          style={{
            marginTop: rhythm(1),
            marginBottom: 0,
          }}
        >
          {post.frontmatter.title}
        </h1>
        {post.frontmatter.showDate ? (
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {post.frontmatter.date}
          </p>
        ) : (
          <div style={{ marginBottom: rhythm(1) }}></div>
        )}
        <div>
          {/* Source: https://www.idesignpixels.com/posts/adsense-in-gatsby */}
          {renderAst(this.props.data.markdownRemark.htmlAst)}
        </div>
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        {/* <Bio /> */}

        {/* <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul> */}
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        lang
        date(formatString: "MMMM DD, YYYY")
        showDate
        image {
          publicURL
        }
        imageType
        schema {
          _type
          name
          image {
            url {
              publicURL
            }
            width
            height
          }
          step {
            name
            text
          }
        }
      }
      htmlAst
    }
  }
`
