import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


import Layout from "../components/layout"
import SEO from "../components/seo"
import { GDPRConsent } from "../components/gdpr-consent"
import { rhythm } from "../utils/typography"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Add Subtitles.tv" />
        <GDPRConsent />

        <p style={{ marginTop: "2rem" }}>
          Welcome! AddSubtitles.tv is a site that helps you discover better ways
          to consume your favorite shows with the assistance of subtitles. Check
          back often for new featured shows and new techniques!
        </p>

        {posts.map(({ node }, i) => {
          const title = node.frontmatter.title || node.fields.slug
          const image = getImage(node.frontmatter.image.childImageSharp.gatsbyImageData)
          return (
            <div style={{ contentVisibility: "auto" }} key={node.fields.slug}>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
              {i % 3 === 0 && (
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  <GatsbyImage image={image} alt={title} style={{ margin: 0 }}/>
                </Link>
              )}
            </div>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { lang: { eq: "en" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          fields {
            slug
          }
          frontmatter {
            title
            image {
              childImageSharp {
                gatsbyImageData(width: 650, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        }
      }
    }
  }
`
