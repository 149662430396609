import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class AboutPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="About" />
        <h1>About</h1>
        <p>
          During a trip, where we traveled for business in Japan, we found that
          there were many people looking for subtitles. Streaming services in
          these different countries are not doing a good job of fully providing
          options to residents speaking a diverse range of languages in spite of
          these cities becoming further international.
        </p>
        <p>
          Our mission is to provide as many means as we can to help find the
          right subtitles for our users. Living abroad can be difficult enough
          without having to guess what is happening on the show you are
          watching. We're here to help you at AddSubtitles.tv.
        </p>
      </Layout>
    )
  }
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
