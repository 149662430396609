module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"withWebp":true,"withAvif":true,"srcSetBreakpoints":[200,340,520,890],"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"AddSubtitles.tv","short_name":"AddSubtitles.tv","start_url":"/","background_color":"#ffffff","theme_color":"#0198c3","display":"minimal-ui","icon":"content/assets/app-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b057530de37e29778d8f3cd5af96eaa3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-tracking/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-87964296-2","autoStart":false,"anonymize":false,"controlCookieName":"gdpr-analytics-enabled","cookieFlags":"secure;samesite=none"},"googleAds":{"trackingId":"","anonymize":false,"controlCookieName":"gdpr-marketing-enabled","cookieFlags":"secure;samesite=none"},"hotjar":{"trackingId":"","snippetVersion":"6","controlCookieName":"gdpr-analytics-enabled"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
