import React from "react"

export default () => (
  <div style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
    <iframe
      title="amazon-ad"
      src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=13&l=ur1&category=primemain&banner=0KGQBGGDPF1BVBFX4BG2&f=ifr&linkID=8bd5f7238536c57689e98b8df82faa1d&t=subtitlestv-20&tracking_id=subtitlestv-20"
      width="468"
      height="60"
      scrolling="no"
      border="0"
      marginwidth="0"
      style={{ border: "none" }}
      frameborder="0"
    ></iframe>
  </div>
)
